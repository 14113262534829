<template>
  <div>
  <!-- Button trigger modal -->
  <button type="button" class="btn btn-sm btn-success font-weight-bold" data-bs-toggle="modal" :data-bs-target="'#exampleModal'+compId.uid">
    <i class="fs-1-3 la la-cart-arrow-down"></i>
  </button>
  <!-- Modal -->
  <div class="modal fade" :id="'exampleModal'+compId.uid" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Add Record Delivery</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row mb-3" v-show="success">
            <div class="col-12">
              <div class="alert alert-success" role="alert">
                Successfully added delivery record!
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-12">
              <label class="col-form-label required fw-bold fs-6">Reference</label>
              <input type="text" class="form-control" :class="{'is-invalid' : errors.value && errors.value.reference}" v-model="formData.reference">
              <span class="text-danger" v-if="errors.value && errors.value.reference">{{errors.value && errors.value.reference[0]}}</span>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-12">
              <label class="col-form-label fw-bold fs-6">Delivery Note#</label>
              <input type="text" class="form-control" :class="{'is-invalid' : errors.value && errors.value.deliveryNote}" v-model="formData.deliveryNote">
              <span class="text-danger" v-if="errors.value && errors.value.deliveryNote">{{errors.value && errors.value.deliveryNote[0]}}</span>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-12">
              <label class="col-form-label required fw-bold fs-6">Delivery Date</label>
              <input type="date" class="form-control" :class="{'is-invalid' : errors.value && errors.value.deliveryDate}" v-model="formData.deliveryDate">
              <span class="text-danger" v-if="errors.value && errors.value.deliveryDate">{{errors.value && errors.value.deliveryDate[0]}}</span>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-12">
              <label class="col-form-label required fw-bold fs-6">Manufacture Date</label>
              <input type="date" class="form-control" :class="{'is-invalid' : errors.value && errors.value.manufactureDate}" v-model="formData.manufactureDate">
              <span class="text-danger" v-if="errors.value && errors.value.manufactureDate">{{errors.value && errors.value.manufactureDate[0]}}</span>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-12">
              <label class="col-form-label required fw-bold fs-6">LOT Number</label>
              <input type="text" class="form-control" :class="{'is-invalid' : errors.value && errors.value.lotNumber}" v-model="formData.lotNumber">
              <span class="text-danger" v-if="errors.value && errors.value.lotNumber">{{errors.value && errors.value.lotNumber[0]}}</span>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-12">
              <label class="col-form-label required fw-bold fs-6">Units</label>
              <input type="number" class="form-control" :class="{'is-invalid' : errors.value && errors.value.units}" v-model="formData.units">
              <span class="text-danger" v-if="errors.value && errors.value.units">{{errors.value && errors.value.units[0]}}</span>
            </div>
          </div>


        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" :id="'close-modal'+compId.uid">Close</button>
          <button
            type="submit"
            :id="'addSubmitBtn'+compId.uid"
            class="btn btn-primary fw-bolder"
            @click="submitForm();"
          >
            <span class="indicator-label">
              Save
            </span>

            <span class="indicator-progress">
              Please wait...
              <span
                class="spinner-border spinner-border-sm align-middle ms-2"
              ></span>
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script lang="ts">
import { Actions } from "@/store/enums/SupplierOrderRecordDeliveriesEnums.ts";
import { useStore } from 'vuex';
import { ref, reactive, onMounted, getCurrentInstance } from 'vue';
 export default {
   name : 'AddRecordDelivery',
   props : ["id"],
   emits: ['input'],
   setup(props, { emit }){
     const store = useStore();

     const compId = getCurrentInstance() as any;

     const success = ref(false)

     const errors = reactive({
       value : {}
     });


     const formData = reactive({
       product_order_id : props.id,
       reference : '',
       deliveryNote : '',
       deliveryDate : null,
       manufactureDate : null,
       lotNumber : '',
       units : null
     })

     onMounted(() => {

       const myModalEl = document.getElementById('exampleModal'+compId.uid) as any;

       myModalEl.addEventListener('hidden.bs.modal', function () {
         success.value = false;
         errors.value = [];
         formData.reference = '';
         formData.deliveryNote = '';
         formData.deliveryDate = null;
         formData.manufactureDate = null;
         formData.lotNumber = '';
         formData.units = null;
       })
     })


     const submitForm = () => {

       const submitButton = ref<HTMLElement | null>(document.getElementById('addSubmitBtn'+compId.uid) as HTMLElement);

       if(submitButton.value){
         submitButton.value.setAttribute("data-kt-indicator", "on");
       }

       store.dispatch(Actions.ADD_SUPPLIER_ORDER_PRODUCT_DELIVERY,formData).then(() => {
         errors.value = [];
         success.value = true;
         setTimeout(function(){

           const closeModal = document.getElementById('close-modal'+compId.uid) as HTMLElement;
           closeModal.click();
           emit('input');
           submitButton.value?.removeAttribute("data-kt-indicator");
         },1000)
       }).catch((response) => {
         setTimeout(function(){
           const input = document.getElementsByClassName('is-invalid')[0] as HTMLElement
           input.focus();
         },100)

         errors.value = response.data.errors;

         submitButton.value?.removeAttribute("data-kt-indicator");

       })
     }

     return {
       props,
       formData,
       errors,
       store,
       submitForm,
       success,
       compId
     }
   }
 }
</script>
