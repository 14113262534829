
import { onMounted, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Actions } from "@/store/enums/SupplierOrderRecordDeliveriesEnums.ts";
import AddRecordDelivery from '@/views/orders/supplier/record_deliveries/AddRecordDelivery.vue';
import EditRecordDelivery from '@/views/orders/supplier/record_deliveries/EditRecordDelivery.vue';
import Table from '@/components/custom/Table.vue';
import DeleteRecord from '@/components/custom/DeleteRecord.vue';
import moment from 'moment'
export default {
  name : 'SupplierOrderRecordDeliveries',
  components : {
    Table,
    AddRecordDelivery,
    EditRecordDelivery,
    DeleteRecord
  },
  setup(){
    const store = useStore();
    const route = useRoute();

    onMounted(() => {
      setCurrentPageBreadcrumbs("Supplier Orders",[
        {title : "Supplier Order",to : "/orders/supplier/edit/"+route.params.id},
        {title : "Record Deliveries", to : "/orders/supplier/recorddeliveries/"+route.params.id}
      ]);
    });

    const actions = computed(() => {
      return Actions;
    })

    const columns = [
      {name : 'code', title : 'Product Code', sortable : true, sort : null, searchable : true},
      {name : 'name', title : 'Product Name', sortable : true, sort : 'desc', searchable : true},
      {name : 'total', title : 'Total Ordered',sortable : true, sort : null, searchable : true},
      {name : 'received', title : 'Total Received',sortable : false, sort : null, searchable : true},
      {name : 'actions', title : 'Record Delivery',sortable : false, sort : null, searchable : false}
    ]

    const columnsDelivered = [
      {name : 'reference', title : 'Reference', sortable : true, sort : 'desc', searchable : true},
      {name : 'code', title : 'Product Code', sortable : true, sort : null, searchable : true},
      {name : 'name', title : 'Product Name', sortable : true, sort : null, searchable : true},
      {name : 'deliveryNote', title : 'Delivery Note#', sortable : true, sort : null, searchable : true},
      {name : 'deliveryDate', title : 'Delivery Date',sortable : true, sort : null, searchable : true},
      {name : 'manufactureDate', title : 'Manufacture Date',sortable : false, sort : null, searchable : true},
      {name : 'lotNumber', title : 'LOT Number',sortable : false, sort : null, searchable : true},
      {name : 'units', title : 'Units',sortable : false, sort : null, searchable : true},
      {name : 'actions', title : 'Edit / Delete',sortable : false, sort : null, searchable : false}
    ]


    return {
      store,
      route,
      actions,
      columns,
      columnsDelivered,
      moment
    }
  }
}
