<template>
  <div>

    <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">

      <div class="card-header cursor-pointer">

        <div class="card-title m-0">
          <h3 class="fw-bolder m-0">Supplier Order - Record Deliveries</h3>
        </div>

      </div>
      <div class="card-body p-7">

        <div class="row mb-10">
          <div class="col-12">

            <div class="mb-3 pb-3 border-bottom">
              <h3 class="fw-400">Order Products</h3>
            </div>

            <Table :columns="columns" ref="table" :action="actions.SUPPLIER_ORDER_PRODUCTS_TO_DELIVER" :id="Number(route.params.id)" :searchEnabled="false">



              <template v-slot:actions="slotProps">

                <div v-show="Number(slotProps.row.received) < Number(slotProps.row.total)">

                  <div class="btn-group" role="group" aria-label="Basic example">

                    <AddRecordDelivery :id="slotProps.row.order_product_id" @input="slotProps.search();$refs.table2.search();"/>

                  </div>

                </div>

              </template>

            </Table>

          </div>
        </div>


        <div class="row mb-3">
          <div class="col-12">

            <div class="mb-3 pb-3 border-bottom">
              <h3 class="fw-400">Delivered Lots</h3>
            </div>

            <Table :columns="columnsDelivered" ref="table2" :action="actions.SUPPLIER_ORDER_PRODUCTS_DELIVERED" :id="Number(route.params.id)" :searchEnabled="false">

              <template v-slot:deliveryDate="slotProps">
                <div>{{moment(new Date(slotProps.row.deliveryDate)).format('DD MMM YYYY')}}</div>
              </template>

              <template v-slot:manufactureDate="slotProps">
                <div>{{moment(new Date(slotProps.row.manufactureDate)).format('DD MMM YYYY')}}</div>
              </template>

              <template v-slot:actions="slotProps">

                <div>

                  <div class="btn-group" role="group" aria-label="Basic example">

                    <EditRecordDelivery :recordDelivery="slotProps.row" @input="slotProps.search();$refs.table.search();"/>

                    <DeleteRecord :id="Number(slotProps.row.id)" :action="actions.DELETE_SUPPLIER_ORDER_PRODUCT_DELIVERY" @input="slotProps.search();$refs.table.search()"/>

                  </div>

                </div>

              </template>

            </Table>

          </div>
        </div>


      </div>


    </div>
  </div>
</template>
<script lang="ts">
import { onMounted, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Actions } from "@/store/enums/SupplierOrderRecordDeliveriesEnums.ts";
import AddRecordDelivery from '@/views/orders/supplier/record_deliveries/AddRecordDelivery.vue';
import EditRecordDelivery from '@/views/orders/supplier/record_deliveries/EditRecordDelivery.vue';
import Table from '@/components/custom/Table.vue';
import DeleteRecord from '@/components/custom/DeleteRecord.vue';
import moment from 'moment'
export default {
  name : 'SupplierOrderRecordDeliveries',
  components : {
    Table,
    AddRecordDelivery,
    EditRecordDelivery,
    DeleteRecord
  },
  setup(){
    const store = useStore();
    const route = useRoute();

    onMounted(() => {
      setCurrentPageBreadcrumbs("Supplier Orders",[
        {title : "Supplier Order",to : "/orders/supplier/edit/"+route.params.id},
        {title : "Record Deliveries", to : "/orders/supplier/recorddeliveries/"+route.params.id}
      ]);
    });

    const actions = computed(() => {
      return Actions;
    })

    const columns = [
      {name : 'code', title : 'Product Code', sortable : true, sort : null, searchable : true},
      {name : 'name', title : 'Product Name', sortable : true, sort : 'desc', searchable : true},
      {name : 'total', title : 'Total Ordered',sortable : true, sort : null, searchable : true},
      {name : 'received', title : 'Total Received',sortable : false, sort : null, searchable : true},
      {name : 'actions', title : 'Record Delivery',sortable : false, sort : null, searchable : false}
    ]

    const columnsDelivered = [
      {name : 'reference', title : 'Reference', sortable : true, sort : 'desc', searchable : true},
      {name : 'code', title : 'Product Code', sortable : true, sort : null, searchable : true},
      {name : 'name', title : 'Product Name', sortable : true, sort : null, searchable : true},
      {name : 'deliveryNote', title : 'Delivery Note#', sortable : true, sort : null, searchable : true},
      {name : 'deliveryDate', title : 'Delivery Date',sortable : true, sort : null, searchable : true},
      {name : 'manufactureDate', title : 'Manufacture Date',sortable : false, sort : null, searchable : true},
      {name : 'lotNumber', title : 'LOT Number',sortable : false, sort : null, searchable : true},
      {name : 'units', title : 'Units',sortable : false, sort : null, searchable : true},
      {name : 'actions', title : 'Edit / Delete',sortable : false, sort : null, searchable : false}
    ]


    return {
      store,
      route,
      actions,
      columns,
      columnsDelivered,
      moment
    }
  }
}
</script>
