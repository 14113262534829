
import { Actions } from "@/store/enums/SupplierOrderRecordDeliveriesEnums.ts";
import { useStore } from 'vuex';
import { ref, reactive, onMounted, getCurrentInstance } from 'vue';
 export default {
   name : 'AddRecordDelivery',
   props : ["id"],
   emits: ['input'],
   setup(props, { emit }){
     const store = useStore();

     const compId = getCurrentInstance() as any;

     const success = ref(false)

     const errors = reactive({
       value : {}
     });


     const formData = reactive({
       product_order_id : props.id,
       reference : '',
       deliveryNote : '',
       deliveryDate : null,
       manufactureDate : null,
       lotNumber : '',
       units : null
     })

     onMounted(() => {

       const myModalEl = document.getElementById('exampleModal'+compId.uid) as any;

       myModalEl.addEventListener('hidden.bs.modal', function () {
         success.value = false;
         errors.value = [];
         formData.reference = '';
         formData.deliveryNote = '';
         formData.deliveryDate = null;
         formData.manufactureDate = null;
         formData.lotNumber = '';
         formData.units = null;
       })
     })


     const submitForm = () => {

       const submitButton = ref<HTMLElement | null>(document.getElementById('addSubmitBtn'+compId.uid) as HTMLElement);

       if(submitButton.value){
         submitButton.value.setAttribute("data-kt-indicator", "on");
       }

       store.dispatch(Actions.ADD_SUPPLIER_ORDER_PRODUCT_DELIVERY,formData).then(() => {
         errors.value = [];
         success.value = true;
         setTimeout(function(){

           const closeModal = document.getElementById('close-modal'+compId.uid) as HTMLElement;
           closeModal.click();
           emit('input');
           submitButton.value?.removeAttribute("data-kt-indicator");
         },1000)
       }).catch((response) => {
         setTimeout(function(){
           const input = document.getElementsByClassName('is-invalid')[0] as HTMLElement
           input.focus();
         },100)

         errors.value = response.data.errors;

         submitButton.value?.removeAttribute("data-kt-indicator");

       })
     }

     return {
       props,
       formData,
       errors,
       store,
       submitForm,
       success,
       compId
     }
   }
 }
